import React from 'react';
import { Metadata } from '@app/components';
import { useLanguage } from '@app/components/Hooks';
import * as Translations from '@app/locales';

export const HOFInducteeMetadata = ({ name = '', imageUrl = '' }): React.ReactElement => {
  const language = useLanguage();
  const title = Translations[language]?.['hof.inductee.metadata.title']?.replace('{name}', name);
  const description = Translations[language]?.['hof.inductee.metadata.description']?.replace('{name}', name);

  return (
    <Metadata
      seo={{
        title,
        description,
        socialTitle: title,
        socialDescription: description,
        socialImageUrl: imageUrl,
        canonicalTag: '',
        meta: true,
      }}
    />
  );
};

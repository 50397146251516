import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { openCenteredPopup } from '@app/helpers/popupHelper';
import { useShareIcons } from '@app/components/SocialSharing/SocialSharingHooks';
import { ShareIconsItem, ShareIconsType } from '@app/types/configurationTypes';
import { SocialWeChat } from '@app/components/SocialSharing/components';

export const shareUrlsMap: {
  [key in ShareIconsType]: string;
} = {
  [ShareIconsType.Facebook]: 'https://facebook.com/sharer/sharer.php?u={url}&t={text}',
  [ShareIconsType.Twitter]: 'https://twitter.com/intent/post?text={text}&url={url}',
  [ShareIconsType.WhatsApp]: 'https://api.whatsapp.com/send?text={url}',
  [ShareIconsType.Weibo]: 'https://service.weibo.com/share/share.php?url={url}',
  [ShareIconsType.WeChat]: '',
};

interface SharingButtonProps {
  shareIcon?: ShareIconsItem;
  url: string;
}

const SharingButton = ({
  url, shareIcon,
}: SharingButtonProps): React.ReactElement => {
  const [modalType, setModalType] = useState<ShareIconsType | null>(null);

  const onShare = (): void => {
    if (!shareIcon?.type) return;
    if (shareIcon?.type === ShareIconsType.WeChat) {
      setModalType(shareIcon?.type);
    } else {
      const text = document.querySelector('meta[property="og:title"]')?.getAttribute('content') ?? '';
      const shareUrl = shareUrlsMap[shareIcon?.type]
        .replace('{text}', text)
        .replace('{url}', document.URL);

      openCenteredPopup(shareUrl, shareIcon?.type, 626, 436);
    }
  };

  return (
    <>
      {shareIcon?.icon?.url && (
        <ShareLink onClick={onShare}>
          <img src={shareIcon?.icon?.url} alt={shareIcon?.icon?.description} />
        </ShareLink>
      )}
      {shareIcon?.type === ShareIconsType.WeChat && (
        <SocialWeChat
          url={decodeURIComponent(url)}
          open={modalType === ShareIconsType.WeChat}
          onClose={(): void => setModalType(null)}
        />
      )}
    </>
  );
};

interface SocialSharingProps {
  onlyIcons?: boolean;
  url: string;
}

const SocialSharing = ({ onlyIcons, url }: SocialSharingProps): React.ReactElement => {
  const encodedUrl = url ? encodeURIComponent(url) : '';
  const shareIcons = useShareIcons();

  return (
    <ShareContainer>
      {
        !onlyIcons && (
          <ShareOnText>
            <FormattedMessage id="share" />
          </ShareOnText>
        )
      }
      {shareIcons?.map((shareIcon) => (
        <SharingButton shareIcon={shareIcon} url={encodedUrl} key={shareIcon.type} />
      ))}
    </ShareContainer>
  );
};

const ShareLink = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }
  &:hover {
    cursor: pointer;
  }

  & > img {
    height: 20px;
  }
`;

const ShareOnText = styled.span`
  margin-right: 20px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.26px;
  text-transform: uppercase;
  color: inherit;  // Use defined on page text color
  font-family: ${(props): string => props.theme.fontFamily.milan};
  display: inline-block;
`;

const ShareContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export default SocialSharing;

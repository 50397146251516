import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { WebHallOfFameInductee } from '@app/types/webTemplateTypes';
import { Devices } from '@app/styles';
import { HOFInducteeBiography } from './hof.inductee.bio';
import { HOFInducteeLink } from './hof.inductee.link';

export const HOFInducteeHighlight = (inductee: WebHallOfFameInductee): React.ReactElement => (
  <Card key={inductee.name.full}>
    <Hero>
      <Image
        src={`${inductee.image.hero.url}?w=800&q=100&format=auto`}
        alt={inductee.image.hero?.description}
      />
    </Hero>
    <Info>
      <Name>{inductee.name.full}</Name>
      <HOFInducteeBiography biography={inductee.biography} preview />
      <CtaArea>
        <HOFInducteeLink slug={inductee.slug}>
          <Button>
            <FormattedMessage id="hof.inductee.view-profile" />
          </Button>
        </HOFInducteeLink>
      </CtaArea>
    </Info>
  </Card>
);

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media ${Devices.tabletLarge} {
    flex-direction: row;
    gap: 40px;

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
  }
`;

const Hero = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  aspect-ratio: 45/29;
  background-color: gray;
  overflow: hidden;

  &:after {
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
  }
`;

const Image = styled.img`
  position: absolute;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Info = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 10px 0;
  width: 100%;

  color: black;

  @media ${Devices.tabletLarge} {
    flex: 1;
  }
`;

const Name = styled.h3`
  font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif!important;
  font-size: 20px;
  letter-spacing: normal;
  font-weight: bold;
  color: #d8be6d;
  text-transform: uppercase;
`;

const CtaArea = styled.div`
  width: 150px;
`;

const Button = styled.button`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  font-size: 11px;
  transition: all 0.3s ease;
  width: auto;
  min-width: 95px;
  background: transparent;
  color: #d50000;
  border: 2px solid #d50000;
  padding-left: 20px;
  padding-right: 20px;

  font-family: MilanTypeRev, Helvetica Neue, Helvetica, Arial, sans-serif!important;
  font-weight: bold;
  cursor: pointer;
  line-height: 1;
  border-radius: 2px;

  & h3 {
    font-size: 11px;
    display: flex;
    align-items: center;
  }

  @media (pointer: fine) {
    &:hover {
      transition: all 0.3s ease;
      background: #d50000;
      color: white;
      border: 2px solid #d50000;
    }
  }
`;
